<template>
    <div class="customerGroup">
        <div class="search">
            <div class="search-l">
                <div class="l-item">
                    <div class="item-label">名称:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.name" placeholder="请输入客户名称" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">电话:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
                    </div>
                </div>
                <div class="l-item">
                    <el-button type="warning" @click="loading = true, page = 1, getList()">搜索</el-button>
                    <el-button @click="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
                </div>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="table">
            <div class="table-operation">
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <Table :data="tableData" :total="total" v-loading="loading"
                @size-change="pageNum = $event, loading = true, getList()"
                @current-change="page = $event, loading = true, getList()">
                <el-table-column label="名称" prop="name" align="center"></el-table-column>
                <el-table-column label="手机号" prop="phone" align="center"></el-table-column>
                <el-table-column label="申请时间" prop="created_at" align="center"></el-table-column>
                <el-table-column label="操作" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" @click="operationFn(scope.row, 1)">同意</el-button>
                        <el-button type="danger" size="mini" @click="operationFn(scope.row, 2)">拒绝</el-button>
                    </template>
                </el-table-column>
            </Table>
        </div>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            searchForm: {},
            tableData: [],
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post(`/erp/v1/customer/apply_lst`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 同意/拒绝
        operationFn(item, type) {
            this.$confirm('操作该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = type == 1 ? '/erp/v1/customer/agree' : '/erp/v1/customer/unagree'
                this.$http.post(`${url}`, {
                    id: item.id
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.getList()
                        // 未审批的合作申请
                        let customerApply = window.sessionStorage.getItem('customerApply')
                        customerApply -= 1
                        sessionStorage.setItem('customerApply', customerApply)
                        
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);

                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    },
}
</script>
    
<style lang="scss" scoped></style>